<template>
  <div class="member-container">
    <cube-scroll ref="scroll" :options="{ click: true, bounce: false }">
      <div class="top">
        <!-- <h1 class="h">
          <i class="cubeic-back back" @click="back" />
          <i class="cubeic-setting setting" @click="toLink('/member/setting')" />
        </h1> -->
        <div class="user">
          <div class="avatar">
            <img
              :src="resetUrl(user.avatar)"
              @click="toLink('/member/setting')"
            />
          </div>
          <div class="text">
            <span class="name" v-text="user.nickName" />
          </div>
          <div class="wallet">
            <img src="./img/gold.png" width="13" class="gold" />
            <span class="gold-txt" v-text="`金币：${user.gold}`" />
            <img src="./img/dpp.png" width="15" class="dpp" />
            <span class="dpp-txt" v-text="`斗币：${user.dppIntegral}`" />
          </div>
        </div>
        <ul class="list">
          <li @click="toLink('/member/msg')">
            <div class="icon">
              <img src="./img/msg.png" width="24" />
            </div>
            <div class="name">
              消息
            </div>
          </li>
          <li @click="toLink('/member/level')">
            <div class="icon">
              <img src="./img/level.png" width="25" />
            </div>
            <div class="name">
              我的等级
            </div>
          </li>
          <li @click="toLink('/member/vip')">
            <div class="icon">
              <img src="./img/vip.png" width="26" />
            </div>
            <div class="name">
              会员
            </div>
          </li>
          <li @click="toLink('/member/wallet')">
            <div class="icon">
              <img src="./img/wallet.png" width="22" />
            </div>
            <div class="name">
              金币
            </div>
          </li>
        </ul>
      </div>
      <ul class="menus">
        <li class="border-bottom-1px" @click="toLink('/member/guess')">
          <span class="icon">
            <img src="./img/guess.png" width="16" />
          </span>
          <span class="name">我的竞猜</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/history')">
          <span class="icon">
            <img src="./img/history.png" width="17" />
          </span>
          <span class="name">观看历史</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/payvideo')">
          <span class="icon">
            <img src="./img/buy.png" width="19" />
          </span>
          <span class="name">金币视频</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/guest')">
          <span class="icon">
            <img src="./img/guest.png" width="20" />
          </span>
          <span class="name">邀请用户</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/feedback')">
          <span class="icon">
            <img src="./img/feedback.png" width="24" class="feedback" />
          </span>
          <span class="name">意见反馈</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/service')">
          <span class="icon">
            <img src="./img/service.png" width="17" />
          </span>
          <span class="name">联系客服</span>
          <i class="cubeic-arrow arrow" />
        </li>
        <li class="border-bottom-1px" @click="toLink('/member/about')">
          <span class="icon">
            <img src="./img/about.png" width="17" />
          </span>
          <span class="name">关于我们</span>
          <i class="cubeic-arrow arrow" />
        </li>
      </ul>
    </cube-scroll>
    <f label="我的" />
    <transition name="slide">
      <router-view />
    </transition>
  </div>
</template>
<script>
import f from "@/components/base/footer";
import { mapState } from "vuex";
export default {
  components: {
    f
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  methods: {
    back() {
      this.$router.back();
    },
    toLink(link) {
      this.$router.push(link);
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    }
  }
};
</script>
<style lang="stylus" scoped>
.member-container
  position fixed
  top 0
  right 0
  bottom 53px
  left 0
  overflow hidden
  z-index 10
  .top
    background linear-gradient(to bottom, #2364CF, #2189E2)
    padding-top 44px
    overflow hidden
    .h
      margin 10px 13px
      font-size 17px
      color #fff
      .setting
        float right
        position relative
        top 3px
    .user
      .avatar
        text-align center
        img
          width 76px
          height 76px
          border 4px solid #fff
          border-radius 50%
      .text
        text-align center
        .name
          font-size 17px
          color #fff
      .wallet
        margin-top 16px
        text-align center
        .gold,.dpp,.gold-txt,.dpp-txt
          display inline-block
          vertical-align middle
        .gold-txt,.dpp-txt
          margin 0 7px
          font-size 13px
          color #fff
    .list
      margin 30px 0 18px
      text-align center
      li
        position relative
        display inline-block
        padding 0 24px
        &:after
          display block
          position absolute
          right 0
          top 50%
          margin-top -14px
          content ""
          width 1px
          height 28px
          background rgba(255,255,255,.5)
        &:last-child
          &:after
            display none
        .icon
          height 29px
          line-height 29px
          img
            display inline-block
            vertical-align middle
        .name
          font-size 13px
          color rgba(255,255,255,.5)
  .menus
    padding 10px 14px
    background #fff
    li
      position relative
      padding 10px 13px
      font-size 0
      .icon,.name
        display inline-block
        vertical-align middle
      .icon
        width 30px
        text-align left
      .feedback
        position relative
        left -4px
        margin-right 2px
      .name
        font-size 14px
        color #383A40
      .arrow
        position absolute
        right 13px
        top 50%
        margin-top -7px
        font-size 12px
        color #C6C6C6
</style>
